let url = 'http://localhost:5000/api';

if (process.env.NODE_ENV === "production")
{
    url = 'https://phapi.mespareparts.com/api';//'https://cbapi.mespareparts.com/api'; // url = 'http://inventdb.pstaweesup.com/api';
}
    
export const config = {
    //apiUrl: settings.list[settings.mode]
    //apiUrl: 'https://localhost:5001/api'
    //apiUrl: 'https://localhost:44325/api'
    //apiUrl: 'http://inventdb.pstaweesup.com/api'
    //apiUrl: 'http://localhost:3000/api'
    //apiUrl: window.location.origin
    apiUrl: url
}